<template>
    <b-navbar toggleable="lg">
      <div class="container">
        <b-navbar-brand href="/"> <img src="@/assets/logo_NoBuffer.png" class="p-0" width="66" alt="brancode" ></b-navbar-brand>
        <h1 class="d-inline text-primary mb-0 mt-3"><strong>branCode.</strong></h1>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto pt-3">
            <b-nav-item @click="scrollToElement('services')" class="h5" href="#">Services</b-nav-item>
            <!-- <b-nav-item class="h5" href="#">Team</b-nav-item> -->
            <b-nav-item @click="scrollToElement('contactUs')" class="h5" href="#">Contact</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
</template>

<script>
export default {
  name:'nav-bar',
  methods: {
    scrollToElement(id) {
    const el = document.getElementById(id);

    if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
      el.scrollIntoView({behavior: 'smooth'});
    }
  }
  },
};
</script>

<style scoped>
</style>
