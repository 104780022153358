<template>

  <div id="contactUs"  class="contact jumbotron jumbotron-fluid position-relative mb-0">

    <div class="custom-shape-divider-bottom-1607172987">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
        </svg>
    </div>

    <div class="container">
        <div class="row mt-5 mt-md-0">
        <b-col cols="6" class="d-flex">
          <div class="align-self-center w-100">
            <transition
              enter-class="slide-fade-enter"
              enter-active-class="slide-fade-enter-active">
              <b-form-input v-show='inViewport.now' class="mb-3 shadow-sm" placeholder="Enter your name"></b-form-input>
            </transition>
            <transition
              enter-class="slide-fade-enter"
              enter-active-class="slide-fade2-enter-active">
              <b-form-input v-show='inViewport.now' class="mb-3 shadow-sm" placeholder="Enter your email"></b-form-input>
            </transition>
            <transition
              enter-class="slide-fade-enter"
              enter-active-class="slide-fade3-enter-active">

              <b-form-textarea v-show='inViewport.now' class="mb-3 shadow-sm"
                  id="textarea"
                  placeholder="Enter message..."
                  rows="6"

                ></b-form-textarea>
            </transition>

            <transition
              enter-class="slide-fade-enter"
              enter-active-class="slide-fade4-enter-active">
                <b-button  v-show='inViewport.now' class="shadow-lg" variant="danger" size="lg" >Contact Us</b-button>
            </transition>
          </div>

        </b-col>
        <b-col cols="6" v-show='inViewport.now'>
          <send-mail :key="componentKey" v-show='inViewport.now' :start="startInitDisplayAnimation" :reset="resetAnimation" :send="startSendAnimation"/>
        </b-col>
      </div>
   </div>

  </div>
</template>

<script>
import SendMail from './SendMailAnimation.vue';
import inViewport from 'vue-in-viewport-mixin';

  export default {
    name:'contact',
    mixins: [ inViewport ],
    components: {
      SendMail,
    },
    data() {
      return {
        startInitDisplayAnimation: false,
        startSendAnimation: false,
        resetAnimation: false,
        componentKey:0,
      }
    },

    watch: {
        'inViewport.now': function(visible) {
            console.log('This component is '+( visible ? 'in-viewport' : 'hidden'));

              if(visible) this.startInitDisplayAnimation = true;
              else{
                this.startInitDisplayAnimation = false
                this.startSendAnimation = false;
                this.componentKey += 1;
              }

        }
    }
  }
</script>

<style scoped>

</style>
