<template>
  <div class="row mb-5" id="services" ref="services">
    <b-col sm="6" lg="4" class="d-flex mb-3 mb-lg-5">
      <b-card tag="article" class="shadow">
        <b-card-title>
          <h3>Webpage</h3>
        </b-card-title>
        <b-card-text>
          We specialize in creating engaging websites that convert more customers, making you more successful in the digital world. We design and build custom website that reflect your business.        </b-card-text>
      </b-card>
    </b-col>

    <b-col sm="6" class="mb-3 d-lg-none">
      <b-card class="h-100 shadow">
          <b-card-title>
            <h3>eCommerce</h3>
          </b-card-title>
          <b-card-text>
            We simplify the process of creating eCommerce marketplaces and powerful sales channels through experience-driven design
          </b-card-text>
      </b-card>
    </b-col>

    <b-col cols="8" class="mb-5 d-none d-lg-block">
      <b-card no-body class="shadow">
        <b-row no-gutters>
           <b-col md="6">
            <b-card-body>
              <b-card-title>
                <h3>eCommerce</h3>
              </b-card-title>
              <b-card-text>
                We simplify the process of creating eCommerce marketplaces and powerful sales channels through experience-driven design
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6"
            @mouseenter="animationClassEComm = 'slideCardLeft'"
            @mouseleave="animationClassEComm = 'slideCardFromLeft'">
            <b-card-body class="position-absolute">
              <b-card-title>
                <h3>eCommerce</h3>
              </b-card-title>
              <b-card-text>
                The design and functionality of your eCommerce with sales in mind and conversion rate optimization techniques that <span class="ml-5">converts more customers</span>
              </b-card-text>
            </b-card-body>
            <div :class="[ animationClassEComm, 'card-image-wrapper-right']">
              <img class="rounded-0" src="@/assets/pale-online-shopping.png" alt="">
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

  <!-- second row -->

    <b-col sm="6" class="mb-3 mt-3 d-lg-none">
        <b-card class="h-100 shadow">
            <b-card-title>
              <h3>Enterprise App</h3>
            </b-card-title>
            <b-card-text>
              Enterprise software solutions are an effective way to speed up work processes and reduce human errors. With the right program, we increase productivity and visibility of the workflow, which leads to increased income.
            </b-card-text>
        </b-card>
    </b-col>

    <b-col cols="8" class="mb-5 d-none d-lg-block">
      <b-card no-body class="shadow" >
        <b-row no-gutters>
            <b-col md="6"
            @mouseenter="animationClassMobile = 'slideCardRight'"
            @mouseleave="animationClassMobile = 'slideCardFromRight'">
              <b-card-body class="position-absolute">
                <b-card-title>
                  <h3>Enterprise App</h3>
                </b-card-title>
                <b-card-text>
                  Enterprise software solutions are an effective way to speed up work processes and reduce human errors. With the right program, we increase productivity and visibility of the workflow, which leads to increased income.
                </b-card-text>
            </b-card-body>
              <div :class="[ animationClassMobile, 'card-image-wrapper-left']">
                <img class="img-fluid rounded-0" src="@/assets/pale-online-service.png" alt="">
              </div>

            </b-col>
           <b-col md="6">
            <b-card-body>
              <b-card-title>
                <h3>Enterprise App</h3>
              </b-card-title>
              <b-card-text>
                Our specialty is creating Custom solutions for Business & Enterprise apps that will seamlessly assimilate into your business processes.
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <!-- <b-col sm="6" lg="4" class="mb-5 mt-3 mt-lg-0">
      <b-card  class="h-100 shadow">
        <b-card-title>
          <h3>Mobile App</h3>
        </b-card-title>
        <b-card-text>
          Mobile sales continue to growth in 2021 and mobile application development is more relevant now than ever. We choose the technology with the right balance of quality and price to offer our partners the best solution for their needs.        </b-card-text>
      </b-card>
    </b-col> -->
     <b-col sm="6" lg="4" class="mb-3 mb-lg-5 mt-3 mt-lg-0">
        <b-card class="h-100 shadow">
            <b-card-title>
              <h3>Mobile App</h3>
            </b-card-title>
            <b-card-text>
              Mobile sales continue to growth in 2021 and mobile application development is more relevant now than ever. We choose the technology with the right balance of quality and price to offer our partners the best solution for their needs.
            </b-card-text>
        </b-card>
    </b-col>

  </div>
</template>

<script>
  export default {
    name:'services',
    data() {
      return {
        animationClassEComm:'',
        animationClassMobile:''
      }
    },
    methods: {
      startAnimation() {
        console.log('start')
        this.animationClass = 'slideCardLeft'
      },
      stopAnimation() {
        console.log('stop')
        this.animationClass = 'slideCardRight'
      },
    },
  }
</script>

<style scoped>

</style>
