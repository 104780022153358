<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->

    <navbar/>
     <!-- hero image componen -->
    <hero/>

    <b-container>
      <!-- services component -->
      <services/>


    </b-container>
    <!-- contact us component -->
    <contact />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Hero from './components/Hero.vue';
import Services from './components/Services.vue';
import Contact from './components/Contact.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Hero,
    Services,
    Contact,
  },
};
</script>

<style lang="scss">

</style>
